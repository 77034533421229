import React from 'react';
import { graphql, withPrefix, Link } from 'gatsby';
import Helmet from 'react-helmet';
import Fade from 'react-reveal/Fade';
import Reveal from 'react-reveal/Reveal';
import AnchorLink from 'react-anchor-link-smooth-scroll';
// import Waypoint from 'react-waypoint'
import SEO from '../components/SEO';
import Dots from '../components/Dots';
import Layout from '../layouts/index';
import ContactForm from '../components/ContactForm';
import Img from 'gatsby-image';
import HeroMain from '../images/hero-main.png';
import HeroBase from '../images/hero-base.png';
import Offering1 from '../images/offering1.svg';
import Offering2 from '../images/offering2.svg';
import Offering3 from '../images/offering3.svg';
import Offering4 from '../images/offering4.svg';
import ContactBase from '../images/contact-base.png';
import ContactMain from '../images/contact-main.png';

import Video from '../videos/video.mp4';

export default class Home extends React.Component {
    constructor(props) {
        super(props);       
    }

    playVideo() {
        var myVideo = document.getElementById("productVideo");
        var thumbVideo = document.getElementById("thumbnail-wrapper")
        var btnVideo = document.getElementById("videoBtn")
        if (myVideo.paused) {
            myVideo.classList.add("playVideo");
            myVideo.classList.remove("pauseVideo");
            thumbVideo.classList.add("hideThumb");
            btnVideo.classList.add("hideBtn")
            myVideo.play();
        }
        else {
            myVideo.classList.add("pauseVideo");
            myVideo.classList.remove("playVideo");
            myVideo.pause(); 
        }
            
    }

    render() {
        // stickyNav: false;

        const markdown = this.props.data.allMarkdownRemark.edges;
        const json = this.props.data.allFeaturesJson.edges;
        const faqs = this.props.data.allFaqsJson.edges;
      
       
       
  return (
    <Layout bodyClass="page-home">
      <SEO title="Lilardia Capital" />
      <Helmet>
        <meta
          name="description"
          content="We connect you with your business world."
        />
      </Helmet>
      <div className="intro pt-sm-13">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <h1 className="hero-title">We Connect You <span>with your Business World</span></h1>

              <div className="d-block d-md-none">
                <div className="wr-hero-img mt-7">
                  <div className="hero-img">
                    <Fade delay={200}>
                      <img className="img-fluid" className="hero-base" src={HeroBase} />

                    </Fade>
                    <Fade delay={600}>
                      <img className="img-fluid" className="hero-main" src={HeroMain} />
                      {/* <Img
                        fluid={props.data.heroMain.childImageSharp.fluid}
                        style={{ position: 'absolute', opacity: '0', width: '300px', maxWidth: '100%' }}
                        className="hero-main"
                        critical={true}
                        fadeIn={false}
                        alt="Hero Image"
                      /> */}
                    </Fade>
                  </div>
                </div>
                <Dots />
                <Fade delay={800}>
                <div>
                <span style={{ opacity: "0" }} className="hero-definition">LILARDIA : an indigenous Australian term for "flower"</span>
                </div>
                </Fade>
              </div>

              <p className="text-center text-md-left">
              Whether you are a Purchaser, Supplier or Financier, our vision is to connect you with your business world. We deliver an effective, interactive platform to secure your short-term business financing problems, freeing you up to achieve the best results for your organization.
              </p>
              <div className="intro-btns d-flex d-md-block justify-content-center">
                <Fade bottom>
                  <AnchorLink href='#contact' className="btn btn-primary mr-2">
                    Request Demo
                  </AnchorLink>
                </Fade>
                <Fade bottom delay={200} className="float-left">
                  <AnchorLink href='#more' className="btn btn-outline">
                    Explore More
                  </AnchorLink>
                </Fade>
              </div>
            </div>
            <div className="col-md-5 d-none d-md-block">
              <div className="wr-hero-img" style={{ opacity: "0" }}>
                <div className="hero-img">
                  <Fade delay={200}>
                    <img className="img-fluid" className="hero-base" src={HeroBase} />

                  </Fade>
                  <Fade delay={600}>
                    <img className="img-fluid" className="hero-main" src={HeroMain} />
                    {/* <Img
                      fluid={props.data.heroMain.childImageSharp.fluid}
                      style={{ position: 'absolute', opacity: '0', width: '300px', maxWidth: '100%' }}
                      className="hero-main"
                      critical={true}
                      fadeIn={false}
                      alt="Hero Image"
                    /> */}
                  </Fade>
                </div>
              </div>
              <Dots />
              <Fade delay={800}>
              <div>
              <span style={{ opacity: "0" }} className="hero-definition">LILARDIA : an indigenous Australian term for "flower"</span>
              </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
      <section id="more" className="mt-10 py-10">
        <div className="container text-center">
          <div className="row justify-content-center">
            <div className="col-12">
              <h2 className="title-2 text-primary mb-4 text-center">Services we offer to blossom your Business into the Reality you once imagined</h2>
            </div>
            {json.map(edge => (
              <div key={edge.node.id} className="col-12 col-md-6 col-lg-4 mb-2">
                <div className="feature">
                  {edge.node.image && (
                      <img className="feature-image" src={withPrefix(edge.node.image)} />
                  )} 
                  <div className="feature-content">
                    <div className="feature-inner">
                      <h2 className="feature-title">{edge.node.title}</h2>
                      <div className="feature-description">{edge.node.description}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Link to="/services" className="btn btn-primary mt-3">More about our offerings</Link>
        </div>
      </section>
      <section className="video-section pt-7">
        <div className="container">
          <h2 className="title-2 text-dark mb-4 text-center">We listen, solve and deliver with trust</h2>
          <Fade>
            <div className="video-wrapper mb-5">
                <div id="thumbnail-wrapper">
                    <Img
                        fluid={this.props.data.vidThumb.childImageSharp.fluid}
                        style={{}}
                        className="vid-thumb"
                        critical={true}
                        fadeIn={false}
                        alt="Hero Image"
                    />
                </div>
           
            <button className="btn video-btn" id="videoBtn" onClick={this.playVideo}>
                <i className="fa fa-play"/>
            </button>
            <video controls id="productVideo" width='100%' style={{ display: "none" }}>
                <source src={Video} />
            </video> 
          </div>            
          </Fade>
          <hr className="section-divider mt-10" />
          <Reveal effect="floatUp" duration={2000}>
          <div className="circle big op-1" style={{top: 0, right: 0}}></div>
          </Reveal>
          <Reveal effect="floatUp" delay={100}  duration={2000}>
          <div className="circle medium op-2" style={{bottom: 50, left: 50}}></div>
          </Reveal>
          <Reveal effect="floatUp" delay={400} duration={2000}>
          <div className="circle small op-3" style={{top: -50, right: 70}}></div>
          </Reveal>
        </div>
      </section>

      <section className="pt-6 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-md-last">
              <h3 className="mb-3 title-2 text-primary">Join the Lilardia Platform</h3>
              <ul className="custom-list primary mb-3">
                <li>A digital, domestic and cross border platform</li>
                <li>Facilitates invoice payment processing and invoice financing workflows</li>
                <li>Assisting corporates and SME’s to collaborate with financial institutions, family trusts, fund managers</li>
                <li>Automated, transparent and auditable platform utilizing the latest technology including machine learning and block chain</li>
              </ul>
              <div className="text-center text-md-left mb-3 mb-md-0">
                <Link to='/services' className="btn btn-primary" >Learn More</Link>
              </div>
            </div>
            <div className="col-md-6 order-md-first">
              <Reveal effect="floatUp" duration={2000}>
                <div className="circle small op-1" style={{top: 100, right: 100}}></div>
              </Reveal>
              <div className="wr-service-img">
                <Img
                  fluid={this.props.data.offeringImg2.childImageSharp.fluid}
                  className=""
                  critical={true}
                  fadeIn={false}
                  alt="Financial Network"
                />
              </div>
            </div>
          </div>
          {/*} <div className="offerings">
           <div className="offering">
                <div className="offering-inner">
                  <div className="offering-content">
                    <h3>Effective Interactive Platform</h3>
                    <ul className="custom-list primary">
                      <li>A digital, domestic and cross border platform</li>
                      <li>Facilitates invoice payment processing and invoice financing workflows</li>
                      <li>Assisting corporates and SME’s to collaborate with financial institutions, family trusts, fund managers</li>
                      <li>Automated, transparent and auditable platform utilizing the latest technology including machine learning and block chain</li>
                    </ul>
                    <Link to='/services' className="btn btn-primary" >Learn More</Link>
                  </div>
                  <Fade>
                   <div className="offering-img">
                      <Img
                        fluid={props.data.offeringImg2.childImageSharp.fluid}
                        className=""
                        critical={true}
                        fadeIn={false}
                        alt="Financial Network"
                      />
                      <Dots />
                  </div>
                  </Fade>
                </div>
             </div>
            
          </div> */}
        </div> 
      </section>
     
     
      
      <section className="resource-section py-10">
        <Reveal effect="customFade">
          <div className="wr-svg wr-svg1">
            <svg xmlns="http://www.w3.org/2000/svg" width="1921.756" height="359.261" viewBox="0 0 1921.756 359.261">
              <path id="Path_805" data-name="Path 805" d="M-16293.918,14291.064s418.2,212.927,723.241,272.335,249.892-165.967,456.407-165.967,247.065,195.2,422.462,143.333,313.063-248.948,313.063-248.948v358.508H-16300.5Z" transform="translate(16300.5 -14291.064)" fill="rgba(0,70,204,0.15)" opacity="0.36"/>
            </svg>
          </div>
        </Reveal>
        <Reveal effect="customFade">
          <div className="wr-svg wr-svg2">
            <svg xmlns="http://www.w3.org/2000/svg" width="1918.554" height="354.622" viewBox="0 0 1918.554 354.622">
              <path id="Path_804" data-name="Path 804" d="M-16300.5,14629.466s-32.924-248.146,247.013-333.017,328.306,105.854,577.111,132.693,541.275-167.512,739.3-132.693,353.932,132.693,353.932,132.693v199.116Z" transform="translate(16301.694 -14274.844)" fill="rgba(0,53,154,0.07)" opacity="0.96"/>
            </svg>
          </div>
        </Reveal>
        <div className="container text-center">
          <h2 className="title-2 text-primary mb-3">How our goals align with yours</h2>
          <p className="section-description text-dark mb-4 px-lg-5">Discover how Lilardia capital as a market place and an enterprise application software provider helps you with blossoming your business into the reality once imagined.</p>
          <Link className="btn btn-primary mt-2" to="/aboutUs">
          About Us
          </Link>
        </div>
      </section>

      {/* <section className="news-section py-12">
        <div className="container">
          <h2 className="title-2 text-center text-primary mb-5">Explore the latest news in the Supply-Chain ecosystem</h2>
          <div className="row">
            {markdown.map(edge => (
              <div key={edge.node.frontmatter.path} className="col-12 col-md-4 mb-1">
                <div className="news-item">
                  <div className="card-content">
                    <h2 className="news-title">
                      {edge.node.frontmatter.title}
                    </h2>
                    <p className="news-exerpt">{edge.node.excerpt}</p>
                    <Link to={edge.node.frontmatter.path}>Read More</Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="text-center mt-4">
              <Link className="btn btn-primary mt-2" to="/services">
                More News
              </Link>
          </div>
          
          <Reveal effect="floatUp" delay={200} duration={1500}>
            <div className="circle small op-3" style={{bottom: -40, left: 70}}></div>
          </Reveal>
          <Reveal effect="floatUp" duration={1500}>
            <div className="circle big op-1" style={{bottom: 0, left: 0}}></div>
          </Reveal>
        </div>
        
      </section> */}

       {/* <section className="faq-section py-12">
        <Img
          fluid={this.props.data.bgFaq.childImageSharp.fluid}
          className="bg-faq"
          critical={true}
          fadeIn={false}
          alt="FAQ"
        />
        <div className="container">
          <div className="row justify-content-start">
            <div className="col-12 text-center">
              <h2 className="title-2 text-light mb-3">Have any questions?</h2>
              <p className="section-description text-light mb-4 px-lg-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam et velit ac purus facilisis suscipit vitae vel ante. Praesent dui tellus, fermentum vel semper a, consequat et magna.</p>
            </div>

            {faqs.map(edge => (
            <div key={edge.node.id} className="col-12 col-md-6 col-lg-4 mb-2">
              <div className="faq">
                <h2 className="faq-title">{edge.node.question}</h2>
                <div className="faq-answer">{edge.node.answer}</div>
              </div>
            </div>
            ))}

            <div className="col-12 text-center">
              <Link className="btn btn-secondary mt-2" to="/services">
                More FAQs
              </Link>
            </div>
          </div>
        </div>
      </section> */}
      <section className="contact-section py-10" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="wr-contact-img" style={{ opacity: "0" }}>
                <div className="contact-img">
                  <Fade>
                    <img className="img-fluid" className="contact-base" src={ContactBase} />
                  </Fade>
                  <Fade delay={400}>
                     <img className="img-fluid" className="contact-main" src={ContactMain} />
                    {/* <Img
                      fluid={this.props.data.contactMain.childImageSharp.fluid}
                      style={{ position: 'absolute', opacity: '0', width: '300px', maxWidth: '100%' }}
                      className="contact-main"
                      critical={true}
                      fadeIn={false}
                      alt="Contact Image"
                    /> */}
                  </Fade>
                </div>
              </div>
               <Dots />
              
            </div>
            <div className="col-md-6">
              <h2 className="title-2 text-primary mb-3">One-on-one communication with our clients is at the heart of who we are</h2>
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
       
      
    </Layout>)
  }
}





export const query = graphql`
  query {
    heroBase: file(relativePath: { eq: "hero-base.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    heroMain: file(relativePath: { eq: "hero-main.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    contactMain: file(relativePath: { eq: "contact-main.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vidThumb: file(relativePath: { eq: "video-thumbnail.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    offeringImg1: file(relativePath: { eq: "offering-img1.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    offeringImg2: file(relativePath: { eq: "offering-img2.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    offeringImg3: file(relativePath: { eq: "offering-img3.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    offeringImg4: file(relativePath: { eq: "offering-img4.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgFaq: file(relativePath: { eq: "bg-faq.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMarkdownRemark (
      filter: { fileAbsolutePath: { regex: "/news/" } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            date(formatString: "DD MMMM YYYY")
          }
          excerpt
        }
      }
    }
    allFeaturesJson {
      edges {
        node {
          id
          title
          description
          image
        }
      }
    }
    allFaqsJson(limit: 3) {
      edges {
        node {
          id
          question
          answer
        }
      }
    }
  }
`;
